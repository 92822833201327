import React from "react";

import TextDisplay from "./LessonRenderComponents/TextDisplay/TextDisplay";
import GraphemeVisualReview from "./LessonRenderComponents/GraphemeVisualReview/GraphemeVisualReview";
import BlendingReview from "./LessonRenderComponents/BlendingReview/BlendingReview";

import "./LessonTypeRenderer.scss";

const LessonTypeRenderer = ({
  lessonType,
  content,
  currentSlide,
  currentIteration,
  setTotalIterations,
  setCurrentIteration
}) => {
  switch (lessonType) {
    case "text_display":
      return (
        <TextDisplay
          content={content}
          setTotalIterations={setTotalIterations}
        />
      );
    case "graphemes_visual_review":
      return (
        <GraphemeVisualReview
          content={content}
          currentSlide={currentSlide}
          setTotalIterations={setTotalIterations}
          currentIteration={currentIteration}
        />
      );
    case "blending_review":
      return (
        <BlendingReview
          content={content}
          currentSlide={currentSlide}
          setTotalIterations={setTotalIterations}
          currentIteration={currentIteration}
          setCurrentIteration={setCurrentIteration}
        />
      );
    default:
      return <div>Unknown Lesson Type</div>;
  }
};

export default LessonTypeRenderer;
