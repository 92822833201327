import React, { useEffect, useState } from "react";
import {
  fetchPhoto,
  handleSlideTransition,
  calculateIterations,
  getGraphemeType,
} from "./GraphemeVisualReview.logic";

import "../styles.scss";
import "./GraphemeVisualReview.scss";

const GraphemeVisualReview = ({
  content,
  currentSlide,
  currentIteration,
  setTotalIterations,
}) => {
  const [fadeClass, setFadeClass] = useState("fade-in");
  const [displayedSlide, setDisplayedSlide] = useState(currentSlide);
  const [photoData, setPhotoData] = useState(null);
  const [showImage, setShowImage] = useState(false);
  const [showGraphemes, setShowGraphemes] = useState(false);

  useEffect(() => {
    const photoName = content[currentSlide]?.photo;

    if (photoName && photoName !== "null") {
      fetchPhoto(photoName, setPhotoData);
    }

    calculateIterations(photoName, setTotalIterations);

    return handleSlideTransition(
      currentSlide,
      displayedSlide,
      setFadeClass,
      setDisplayedSlide
    );
  }, [currentSlide, content, setTotalIterations, displayedSlide]);

  useEffect(() => {
    setShowImage(currentIteration === 1);

    if (currentIteration === 1) {
      setTimeout(() => setShowGraphemes(true), 500);
    } else {
      setShowGraphemes(false);
    }
  }, [currentIteration]);

  const slideData = content[displayedSlide] || {};
  const graphemeTypeClass = getGraphemeType(
    slideData.grapheme,
    slideData.isVowel
  );
  const graphemeCardClass =
    currentIteration > 0 ? "grapheme-card minimized" : "grapheme-card";

  const graphemes = slideData.graphemes || [];

  return (
    <main className="lesson-main">
      <div className={`${graphemeCardClass} ${graphemeTypeClass} ${fadeClass}`}>
        <h1>{showGraphemes ? slideData.phoneme : slideData.grapheme}</h1>
      </div>
      {showImage && photoData && (
        <img
          src={photoData}
          alt={slideData.alt || "Grapheme visual"}
          className={`photo ${fadeClass}`}
        />
      )}
      {showGraphemes && graphemes.length > 0 && (
        <div
          className={`grapheme-tiles right-position ${
            graphemes.length > 5 ? "two-columns" : ""
          }`}
        >
          {graphemes.map((grapheme, index) => (
            <div key={index} className="grapheme-tile">
              {grapheme}
            </div>
          ))}
        </div>
      )}
    </main>
  );
};

export default GraphemeVisualReview;
