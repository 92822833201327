import React from "react";

import "./AboutUs.scss";

const AboutUs = () => {
  return (
    <div>About Early Reader</div>
  );
};

export default AboutUs;
