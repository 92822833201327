import React from "react";

const Policy = () => {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <p>Effective Date: [9/12/2024]</p>
      <p>
        Welcome to EarlyReaderAI! Your privacy is important to us. This Privacy
        Policy explains how we collect, use, disclose, and protect your
        information when you use our app.
      </p>
      <h2>1. Information We Collect</h2>
      <p>
        We may collect the following types of information to provide you with an
        optimized learning experience:
      </p>
      <ul>
        <li>
          Personal Information: We may collect personal information such as
          names and email addresses if you choose to create an account or
          provide feedback.
        </li>
        <li>
          Usage Data: Information on how you interact with the app, including
          time spent on different activities, high-frequency word performance,
          and progress tracking data.
        </li>
        <li>
          Device Information: Details about the device you use to access
          EarlyReaderAI, such as device type, operating system, and unique
          device identifiers.
        </li>
      </ul>
      <h2>2. How We Use Your Information</h2>
      <p>We use the collected information to:</p>
      <ul>
        <li>Provide, maintain, and improve our app and its features.</li>
        <li>
          Track and display your progress in vocabulary and reading
          comprehension.
        </li>
        <li>
          Enhance user experience with personalized content and tailored
          instruction.
        </li>
        <li>
          Develop new features, such as interactive animations, customizable
          word selection, and AI voice recognition.
        </li>
      </ul>
      <h2>3. Information Sharing and Disclosure</h2>
      <p>
        We do not sell or share your personal information with third parties
        except in the following cases:
      </p>
      <ul>
        <li>
          Service Providers: We may share information with third-party service
          providers to facilitate the app’s functions (e.g., data analytics,
          customer support).
        </li>
        <li>
          Legal Requirements: We may disclose your information if required by
          law or in response to a valid legal request.
        </li>
      </ul>
      <h2>4. Data Security</h2>
      <p>
        We take your data security seriously. We use appropriate technical and
        organizational measures to protect your information from unauthorized
        access, loss, misuse, or alteration. However, please note that no method
        of transmission over the internet or electronic storage is 100% secure.
      </p>
      <h2>5. Children's Privacy</h2>
      <p>
        EarlyReaderAI is designed for use by students and children under the
        supervision of educators, tutors, or parents. We do not knowingly
        collect personal information from children under the age of 13 without
        parental consent. If you are a parent or guardian and believe that your
        child has provided us with personal information, please contact us
        immediately.
      </p>
      <h2>6. Your Choices and Rights</h2>
      <p>You have the right to:</p>
      <ul>
        <li>Access and review your personal information.</li>
        <li>Request correction or deletion of your personal information.</li>
        <li>Opt out of certain data collection practices.</li>
      </ul>
      <p>
        To exercise these rights, please contact us at
        [earlyreaderai@gmail.com].
      </p>
      <h2>7. Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page. Please
        review this Privacy Policy periodically for any changes.
      </p>
      <h2>8. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy or our
        data practices, please contact us at [earlyreaderai@gmail.com].
      </p>
      <p>
        Thank you for choosing EarlyReaderAI to support your child’s learning
        journey!
      </p>
    </div>
  );
};

export default Policy;
