import axios from "axios";

export const isAuthenticated = async () => {
  try {
    const response = await axios.post("/graphql", {
      query: `
          query {
            getUser {
              user_id
            }
          }
        `,
    });
    return response.data.data.getUser !== null;
  } catch (error) {
    return false;
  }
};

export const logout = async () => {
  try {
    await axios.post("/graphql", {
      query: `
        mutation {
          logout {
            message
          }
        }
      `,
    });
    window.location.href = "/login";
  } catch (error) {
    console.error("Error logging out:", error);
  }
};
