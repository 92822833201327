import React, { useState, useEffect, useRef } from "react";
import {
  calculateVowelAndQuOffset,
  applyPhonemeProperties,
  handleSlideTransition,
  calculateIterations,
  fetchPhonemeTiles,
} from "./BlendingReview.logic";

import "./BlendingReview.scss";

const BlendingReview = ({
  content,
  currentSlide,
  setTotalIterations,
  currentIteration,
  setCurrentIteration
}) => {
  const [currentPhonemeIndex, setCurrentPhonemeIndex] = useState(-1);
  const [arrowPhase, setArrowPhase] = useState(0);
  const [displayedSlide, setDisplayedSlide] = useState(currentSlide);
  const [fadeClass, setFadeClass] = useState("fade-in");
  const [phonemeTiles, setPhonemeTiles] = useState([]);
  const tileRefs = useRef([]);
  const [animatedTiles, setAnimatedTiles] = useState([]);
  const [areRefsReady, setAreRefsReady] = useState(false);
  const [phonemeData, setPhonemeData] = useState({
    phonemeResponse: [],
    flattenedPhonemes: [],
    ipaPhonemes: [],
    graphemeTiles: [],
  });
  const [phonemeState, setPhonemeState] = useState({
    vowelIndex: -1,
    quOffset: 0,
    combinedQuIndex: null,
  });

  const word = content[displayedSlide]?.word || "";

  useEffect(() => {
    return handleSlideTransition(
      currentSlide,
      displayedSlide,
      setFadeClass,
      setDisplayedSlide
    );
  }, [currentSlide, displayedSlide]);

  useEffect(() => {
    const fetchAndSetPhonemes = async () => {
      const data = await fetchPhonemeTiles(word);
      setPhonemeData(data);
      setPhonemeTiles(data.graphemeTiles);
      setDisplayedSlide(currentSlide);

      calculateIterations(data.graphemeTiles.length, setTotalIterations);
    };

    fetchAndSetPhonemes();
  }, [currentSlide, word, setTotalIterations]);

  useEffect(() => {
    if (phonemeTiles.length && tileRefs.current.every((ref) => ref !== null)) {
      setAreRefsReady(true);
    }
  }, [phonemeTiles]);

  useEffect(() => {
    if (areRefsReady) {
      const { vowelIdx, quOffset, combinedQuIndex } = calculateVowelAndQuOffset(
        phonemeTiles,
        tileRefs
      );

      console.log({ vowelIdx, quOffset, combinedQuIndex });

      setPhonemeState({
        vowelIndex: vowelIdx >= 0 ? vowelIdx : phonemeTiles.length - 1,
        quOffset,
        combinedQuIndex,
      });
    }
  }, [areRefsReady, phonemeTiles]);

  useEffect(() => {
    const { vowelIndex, combinedQuIndex } = phonemeState;

    if (vowelIndex === -1) return;

    if (currentIteration === 0) {
      setCurrentPhonemeIndex(-1);
      setArrowPhase(0);
      setAnimatedTiles([]);
    } else if (currentIteration <= vowelIndex + 1) {
      setArrowPhase(1);
      if (
        combinedQuIndex !== null &&
        currentIteration === combinedQuIndex + 1
      ) {
        setAnimatedTiles([combinedQuIndex, combinedQuIndex + 1]);
        setCurrentPhonemeIndex(combinedQuIndex);
      } else if (phonemeTiles[currentIteration - 2]?.toLowerCase() === "q") {
        setCurrentPhonemeIndex(currentIteration);
        setAnimatedTiles([currentIteration]);
        setCurrentIteration(currentIteration + 1)
      } else {
        setCurrentPhonemeIndex(currentIteration - 1);
        setAnimatedTiles([currentIteration - 1]);
      }
    } else if (arrowPhase === 1) {
      setArrowPhase(2);
      setCurrentPhonemeIndex(vowelIndex);
      setAnimatedTiles(Array.from({ length: vowelIndex + 1 }, (_, i) => i));
    } else if (currentIteration === phonemeTiles.length + 2) {
      setArrowPhase(4);
      setAnimatedTiles(
        Array.from({ length: phonemeTiles.length }, (_, i) => i)
      );
    } else if (
      (arrowPhase === 2 || arrowPhase === 3) &&
      currentIteration > vowelIndex + 2
    ) {
      setArrowPhase(3);
      const nextIndex = vowelIndex + (currentIteration - (vowelIndex + 2));
      setCurrentPhonemeIndex(nextIndex);
      setAnimatedTiles([nextIndex]);
    }
  }, [currentIteration, phonemeState, arrowPhase, phonemeTiles]);

  if (!phonemeTiles.length) {
    return <div className="blending-review">Loading phonemes...</div>;
  }

  const currentTile = tileRefs.current[currentPhonemeIndex];
  const vowelTile = tileRefs.current.find((tile, index) => {
    const phoneme = phonemeTiles[index];
    return phoneme && /[aeiou]/i.test(phoneme);
  });

  return (
    <main className="lesson-main">
      <div className={`blending-review ${fadeClass}`}>
        <div className={`phoneme-tiles ${fadeClass}`}>
          {phonemeTiles.map((phoneme, index) => {
            const properties = applyPhonemeProperties({ phonemeData, phoneme });
            const isTeetering =
              arrowPhase === 1 && animatedTiles.includes(index);
            const isWiggling =
              arrowPhase === 2 && animatedTiles.includes(index);
            const isGrowing = arrowPhase === 4 && animatedTiles.includes(index);
            const isAnimating =
              arrowPhase === 3 && animatedTiles.includes(index);

            return (
              <div
                key={index}
                ref={(el) => (tileRefs.current[index] = el)}
                className={`phoneme-tile
                  ${isTeetering ? "teeter" : ""}
                  ${isWiggling ? "teeter" : ""}
                  ${isGrowing ? "grow" : ""}
                  ${isAnimating ? "teeter" : ""}
                  ${properties.includes("spin") ? "spin" : ""}
                  ${properties.includes("cross-out") ? "cross-out" : ""}
                `}
              >
                {phoneme}
              </div>
            );
          })}

          <div
            className="arrow first-arrow"
            style={{
              width:
                phonemeTiles[currentPhonemeIndex]?.toLowerCase() === "q"
                  ? `${
                      (currentTile?.offsetWidth || 0) +
                      (phonemeState.quOffset || 0)
                    }px`
                  : `${currentTile?.offsetWidth || 0}px`,
              left: `${currentTile?.offsetLeft || 0}px`,
              opacity: arrowPhase === 1 ? 1 : 0,
            }}
          ></div>

          <div
            className="arrow second-arrow"
            style={{
              width: `${
                vowelTile
                  ? vowelTile.offsetLeft +
                    vowelTile.offsetWidth -
                    tileRefs.current[0].offsetLeft +
                    phonemeState.quOffset
                  : 0
              }px`,
              left: `${tileRefs.current[0]?.offsetLeft || 0}px`,
              opacity: arrowPhase === 2 ? 1 : 0,
            }}
          ></div>

          <div
            className="arrow third-arrow"
            style={{
              width:
                arrowPhase === 3 ? `${currentTile?.offsetWidth || 0}px` : "0px",
              left: `${currentTile?.offsetLeft || 0}px`,
              opacity: arrowPhase === 3 ? 1 : 0,
            }}
          ></div>

          <div
            className="arrow fourth-arrow"
            style={{
              width: `${
                tileRefs.current[phonemeTiles.length - 1]?.offsetLeft +
                (tileRefs.current[phonemeTiles.length - 1]?.offsetWidth || 0) -
                tileRefs.current[0]?.offsetLeft
              }px`,
              left: `${tileRefs.current[0]?.offsetLeft || 0}px`,
              opacity: arrowPhase === 4 ? 1 : 0,
            }}
          ></div>
        </div>
      </div>
    </main>
  );
};

export default BlendingReview;
