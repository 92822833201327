import axios from "axios";

// Fetch lesson data
export const fetchLessonData = async (
  lesson_id,
  sessionParam,
  slideParam,
  setLesson,
  setCurrentSession,
  setCurrentSlide,
  setTotalSlides,
  setTotalSlidesInAllSessions,
  singleSlideLessonTypes
) => {
  try {
    const response = await axios.post("/graphql", {
      query: `
        query GetLessonSessionById($lesson_id: ID!) {
          getLessonSessionById(lesson_id: $lesson_id) {
            lesson_id
            title
            description
            content
          }
        }
      `,
      variables: { lesson_id },
    });

    const data = response.data.data.getLessonSessionById;
    setLesson(data);
    setCurrentSession(sessionParam);
    setCurrentSlide(slideParam);

    let totalSlidesAllSessions = 0;
    let calculatedSlides = 1;

    data.content.forEach((session, index) => {
      let sessionSlideCount = singleSlideLessonTypes.includes(
        session.lesson_type
      )
        ? 1 // Single-slide session like text_display
        : session.content.length;

      if (index === sessionParam - 1 && calculatedSlides === 1) {
        calculatedSlides = sessionSlideCount;
      }

      totalSlidesAllSessions += sessionSlideCount;
    });

    setTotalSlides(calculatedSlides);
    setTotalSlidesInAllSessions(totalSlidesAllSessions);
  } catch (error) {
    console.error("Error fetching lesson data", error);
  }
};

// Handle next slide/animation navigation
export const handleNextSlide = (
  lesson,
  currentSession,
  setCurrentSession,
  setCurrentSlide,
  totalSessions,
  setTotalSlides,
  setCurrentIteration,
  currentSlide,
  currentIteration,
  totalSlides,
  totalIterations,
  singleSlideLessonTypes
) => {
  const session = lesson.content[currentSession - 1];
  const isSingleSlideSession = singleSlideLessonTypes.includes(
    session.lesson_type
  );

  // If it's a single-slide session, move to the next session
  if (isSingleSlideSession && currentSession < totalSessions) {
    const nextSession = currentSession + 1;
    setCurrentSession(nextSession);
    setCurrentSlide(0);
    setTotalSlides(lesson.content[nextSession - 1].content.length);
    setCurrentIteration(0);
    return;
  }

  // Normal multi-slide navigation
  if (currentIteration < totalIterations - 1) {
    setCurrentIteration((prev) => prev + 1);
  } else if (currentSlide < totalSlides - 1) {
    setCurrentSlide((prev) => prev + 1);
    setCurrentIteration(0);
  } else if (currentSession < totalSessions) {
    const nextSession = currentSession + 1;
    setCurrentSession(nextSession);
    setCurrentSlide(0);
    setTotalSlides(lesson.content[nextSession - 1].content.length);
    setCurrentIteration(0);
  }
};

// Handle previous slide navigation
export const handlePrevSlide = (
  lesson,
  currentSession,
  setCurrentSession,
  setCurrentSlide,
  setTotalSlides,
  setCurrentIteration,
  currentSlide,
  currentIteration,
  singleSlideLessonTypes
) => {
  const session = lesson.content[currentSession - 1];
  const isSingleSlideSession = singleSlideLessonTypes.includes(
    session.lesson_type
  );

  // If it's a single-slide session, move to the previous session
  if (isSingleSlideSession && currentSession > 1) {
    const prevSession = currentSession - 1;
    const prevSessionContentLength =
      lesson.content[prevSession - 1].content.length;

    setCurrentSession(prevSession);
    setCurrentSlide(prevSessionContentLength - 1);
    setTotalSlides(prevSessionContentLength);
    setCurrentIteration(0);
    return;
  }

  // Normal multi-slide navigation
  if (currentIteration > 0) {
    setCurrentIteration(0);
  } else if (currentSlide > 0) {
    setCurrentSlide((prev) => prev - 1);
    setCurrentIteration(0);
  } else if (currentSession > 1) {
    const prevSession = currentSession - 1;
    const prevContent = lesson.content[prevSession - 1].content;

    setCurrentSession(prevSession);
    setCurrentSlide(prevContent.length - 1);
    setTotalSlides(prevContent.length);
    setCurrentIteration(0);
  }
};
