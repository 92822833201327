import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faExpand,
} from "@fortawesome/free-solid-svg-icons";
import ProgressBar from "../../Comp/ProgressBar/ProgressBar";
import LessonTypeRenderer from "../../Comp/LessonTypeRenderer/LessonTypeRenderer";

import {
  fetchLessonData,
  handleNextSlide,
  handlePrevSlide,
} from "./Lesson.func";

import "./Lesson.scss";

const Lesson = ({ toggleFullscreen, fullscreen }) => {
  const [lesson, setLesson] = useState(null);
  const [currentSession, setCurrentSession] = useState(1);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(1);
  const [totalSlidesInAllSessions, setTotalSlidesInAllSessions] = useState(0);
  const [currentIteration, setCurrentIteration] = useState(0);
  const [totalIterations, setTotalIterations] = useState(0);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const lesson_id = params.get("id");
  const sessionParam = parseInt(params.get("session")) || 1;
  const slideParam = parseInt(params.get("slide")) || 0;

  // Memoized single-slide lesson types
  const singleSlideLessonTypes = useMemo(() => ["text_display", ""], []);

  useEffect(() => {
    fetchLessonData(
      lesson_id,
      sessionParam,
      slideParam,
      setLesson,
      setCurrentSession,
      setCurrentSlide,
      setTotalSlides,
      setTotalSlidesInAllSessions,
      singleSlideLessonTypes
    );
  }, [lesson_id, sessionParam, slideParam, singleSlideLessonTypes]);

  if (!lesson) return <div>Loading...</div>;

  const totalSessions = lesson.content.length;

  const completedSlides =
    lesson.content.slice(0, currentSession - 1).reduce((acc, session) => {
      const isSingleSlideSession = singleSlideLessonTypes.includes(
        session.lesson_type
      );
      return acc + (isSingleSlideSession ? 1 : session.content.length);
    }, 0) + currentSlide;

  return (
    <div className="lesson-container">
      <h2>{lesson.title}</h2>
      <div className="lesson-content-container">
        <div
          className={`lesson-content-card ${
            fullscreen && "lesson-content-card-fullscreen"
          }`}
        >
          <LessonTypeRenderer
            lessonType={lesson.content[currentSession - 1].lesson_type}
            content={lesson.content[currentSession - 1].content}
            currentSlide={currentSlide}
            setTotalIterations={setTotalIterations}
            currentIteration={currentIteration}
            setCurrentIteration={setCurrentIteration}
            singleSlideLessonTypes={singleSlideLessonTypes}
          />
        </div>
      </div>
      <div
        className={`lesson-navigation ${
          fullscreen && "lesson-navigation-fullscreen"
        }`}
      >
        <button
          className={`prev-button ${fullscreen && "previous-fullscreen"}`}
          onClick={() => {
            if (buttonsDisabled) return;

            setButtonsDisabled(true);
            setTimeout(() => setButtonsDisabled(false), 250);

            handlePrevSlide(
              lesson,
              currentSession,
              setCurrentSession,
              setCurrentSlide,
              setTotalSlides,
              setCurrentIteration,
              currentSlide,
              currentIteration,
              singleSlideLessonTypes
            );
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <button
          className={`fullscreen-button ${
            fullscreen && "fullscreen-button-fullscreen"
          }`}
          onClick={toggleFullscreen}
        >
          <FontAwesomeIcon icon={faExpand} />
        </button>
        <button
          className={`next-button ${fullscreen && "next-fullscreen"}`}
          onClick={() => {
            if (buttonsDisabled) return;

            setButtonsDisabled(true);
            setTimeout(() => setButtonsDisabled(false), 250);

            handleNextSlide(
              lesson,
              currentSession,
              setCurrentSession,
              setCurrentSlide,
              totalSessions,
              setTotalSlides,
              setCurrentIteration,
              currentSlide,
              currentIteration,
              totalSlides,
              totalIterations,
              singleSlideLessonTypes
            );
          }}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
      <ProgressBar
        fullscreen={fullscreen}
        totalSlidesInAllSessions={totalSlidesInAllSessions}
        completedSlides={completedSlides}
      />
    </div>
  );
};

export default Lesson;
