import axios from "axios";

// Fetch photo data
export const fetchPhoto = async (photoName, setPhotoData) => {
  try {
    const response = await axios.post("/graphql", {
      query: `
        query GetFileByName($file_name: String!) {
          getFileByName(file_name: $file_name) {
            file_content
          }
        }
      `,
      variables: { file_name: photoName },
    });

    const fileData = response.data.data.getFileByName;

    if (fileData && fileData.file_content) {
      setPhotoData(`data:image/jpeg;base64,${fileData.file_content}`);
    } else {
      console.error("No file content returned.");
    }
  } catch (error) {
    console.error("Error fetching photo:", error);
  }
};

// Handle fade transitions and slide updates
export const handleSlideTransition = (
  currentSlide,
  displayedSlide,
  setFadeClass,
  setDisplayedSlide
) => {
  if (currentSlide === displayedSlide) return;

  setFadeClass("fade-out");

  const timer = setTimeout(() => {
    setDisplayedSlide(currentSlide);
    setFadeClass("fade-in");
  }, 400);
};

// Set the total number of iterations based on the presence of a photo
export const calculateIterations = (photoName, setTotalIterations) => {
  const totalIterations = photoName && photoName !== "null" ? 2 : 1;
  setTotalIterations(totalIterations);
};

export const getGraphemeType = (grapheme, isVowel = false) => {
  // Common double consonants
  const doubleConsonants = [
    "ff",
    "ss",
    "ll",
    "zz",
    "bb",
    "dd",
    "gg",
    "nn",
    "pp",
    "rr",
    "tt",
    "mm",
  ];

  // Single consonants
  const singleConsonants = "bcdfghjklmnpqrstvwxyz".split("");

  // Vowels
  const vowels = "aeiou".split("");

  // Vowel teams
  const vowelTeams = [
    "ai",
    "ea",
    "ee",
    "oa",
    "oo",
    "ie",
    "ue",
    "au",
    "oi",
    "oy",
    "ou",
    "ow",
    "ay",
    "ei",
    "ey",
    "ue",
    "ui",
    "ow",
  ];

  // R-controlled vowels
  const rControlledVowels = ["ar", "er", "ir", "or", "ur", "air", "ear", "ier"];

  // "qu" as a digraph
  const digraphs = ["ch", "sh", "th", "wh", "ph", "ck", "ng", "qu"];

  // Common trigraphs
  const trigraphs = ["tch", "sch", "shr", "spl", "squ"];

  // Special combinations
  const specialCombinations = [
    "ild",
    "all",
    "alk",
    "ost",
    "old",
    "ind",
    "unk",
    "ang",
    "ing",
    "ung",
    "ong",
    "onk",
    "ank",
    "ink",
    "onk",
  ];

  // Logic for handling "y" based on the isVowel flag
  if (grapheme === "y") {
    return isVowel ? "vowel-y" : "single-consonant"; // Use the isVowel flag to decide if "y" is a vowel or consonant
  }

  // Logic for categorizing each grapheme
  if (doubleConsonants.includes(grapheme)) return "double-consonant";
  if (vowelTeams.includes(grapheme)) return "vowel-team";
  if (rControlledVowels.includes(grapheme)) return "r-controlled-vowel";
  if (digraphs.includes(grapheme)) return "digraph";
  if (trigraphs.includes(grapheme)) return "trigraph";
  if (specialCombinations.includes(grapheme)) return "special-combination";
  if (vowels.includes(grapheme)) return "vowel";
  if (singleConsonants.includes(grapheme)) return "single-consonant";

  return "default";
};
