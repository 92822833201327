import React, { useEffect } from "react";

import "../styles.scss";

const TextDisplay = ({ content, setTotalIterations }) => {
  useEffect(() => {
    setTotalIterations(1);
  }, [setTotalIterations]);

  return (
    <main className="lesson-main">
      {content.map((item, index) => (
        <h1 key={index} className="lesson-text">
          {item}
        </h1>
      ))}
    </main>
  );
};

export default TextDisplay;
