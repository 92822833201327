import React from "react";

import logo from "../../Utils/Assets/AppIcon/logo512.png";
import "./Watermark.scss";

const Watermark = () => {
  return (
    <img className="watermark" src={logo} alt="Two siblings reading together" />
  );
};

export default Watermark;
