import {
  getPhonemes,
  convertCmuToIpa,
  mapIpaToGraphemes,
} from "../../../../Utils/Phones/phones";

export const fetchPhonemeTiles = async (word) => {
  try {
    const phonemeResponse = await getPhonemes(word);
    const flattenedPhonemes = phonemeResponse.flat();
    const ipaPhonemes = convertCmuToIpa(flattenedPhonemes);
    const graphemeTiles = mapIpaToGraphemes(ipaPhonemes, word);

    return {
      phonemeResponse,
      flattenedPhonemes,
      ipaPhonemes,
      graphemeTiles,
    };
  } catch (error) {
    console.error("Error fetching phonemes:", error);

    return {
      phonemeResponse: [],
      flattenedPhonemes: [],
      ipaPhonemes: [],
      graphemeTiles: [],
    };
  }
};

export const applyPhonemeProperties = ({ phonemeData, phoneme }) => {
  const properties = [];
  const { ipaPhonemes, graphemeTiles } = phonemeData;

  // Define continuous vowel sounds and short/long vowel sound classifications
  const continuousVowels = ["a", "e", "i", "o", "u"];
  const shortVowels = ["æ", "ɛ", "ɪ", "ʌ", "ʊ"];
  const longVowels = ["iː", "uː", "eɪ", "oʊ", "ɑː", "ɔː"];

  // Condition 1: Correct sound without breaking rules => "teeter"
  const isCorrectSound = graphemeTiles.includes(phoneme);
  if (isCorrectSound) {
    properties.push("teeter");
  }

  // Condition 2: Schwa sound (ə) => "spin"
  if (phoneme === "ə") {
    properties.push("spin");
  }

  // Condition 3: Silent tile (no sound) => "cross-out"
  if (phoneme === "e (silent)") {
    properties.push("cross-out");
  }

  // Condition 4: Long vowel sound, not a vowel team => "macron"
  const isLongVowel = longVowels.includes(phoneme);
  const isVowelTeam = graphemeTiles.some((tile) =>
    ["ai", "ea", "oa", "ee", "ie", "ue", "oo"].includes(tile)
  );
  if (isLongVowel && !isVowelTeam) {
    properties.push("macron");
  }

  // Condition 5: Short vowel sound, not a vowel team => "breve"
  const isShortVowel = shortVowels.includes(phoneme);
  if (isShortVowel && !isVowelTeam) {
    properties.push("breve");
  }

  return properties;
};

export const calculateIterations = (totalPhonemes, setTotalIterations) => {
  setTotalIterations(totalPhonemes + 3);
};

export const handleSlideTransition = (
  currentSlide,
  displayedSlide,
  setFadeClass,
  setDisplayedSlide
) => {
  if (currentSlide === displayedSlide) return;

  setFadeClass("fade-out");

  const timer = setTimeout(() => {
    setDisplayedSlide(currentSlide);
    setFadeClass("fade-in");
  }, 400);
};

export const calculateVowelAndQuOffset = (graphemeTiles, tileRefs) => {
  let vowelIdx = -1;
  let quOffset = 0;
  let combinedQuIndex = null;

  for (let index = 0; index < graphemeTiles.length; index++) {
    const phoneme = graphemeTiles[index].toLowerCase();

    if (phoneme === "q" && graphemeTiles[index + 1]?.toLowerCase() === "u") {
      combinedQuIndex = index;
      quOffset = tileRefs.current[index + 1]?.offsetWidth || 0;
      index++;
      continue;
    }

    if (
      /[aeiou]/i.test(phoneme) &&
      !(phoneme === "u" && graphemeTiles[index - 1]?.toLowerCase() === "q")
    ) {
      vowelIdx = index;
      break;
    }
  }

  return { vowelIdx, quOffset, combinedQuIndex };
};
