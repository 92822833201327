import axios from "axios";

const cmuToIpaMap = {
  // Vowels
  AA: "ɑ",
  AE: "æ",
  AH: "ʌ",
  AO: "ɔ",
  AW: "aʊ",
  AY: "aɪ",
  EH: "ɛ",
  ER: "ɜː",
  EY: "eɪ",
  IH: "ɪ",
  IY: "iː",
  OW: "oʊ",
  OY: "ɔɪ",
  UH: "ʊ",
  UW: "uː",

  // Additional Vowel variations for stress markers
  AH0: "ə",
  AH1: "ʌ",
  AH2: "ʌ",
  AO0: "ɔ",
  AO1: "ɔ",
  AO2: "ɔ",
  EH0: "ə",
  EH1: "ɛ",
  EH2: "ɛ",
  EY0: "eɪ",
  EY1: "eɪ",
  EY2: "eɪ",

  // Consonants
  B: "b",
  CH: "tʃ",
  D: "d",
  DH: "ð",
  F: "f",
  G: "g",
  HH: "h",
  JH: "dʒ",
  K: "k",
  L: "l",
  M: "m",
  N: "n",
  NG: "ŋ",
  P: "p",
  R: "r",
  S: "s",
  SH: "ʃ",
  T: "t",
  TH: "θ",
  V: "v",
  W: "w",
  Y: "j",
  Z: "z",
  ZH: "ʒ",

  // R-controlled vowels
  AIR: "eɹ",
  AR: "ɑɹ",
  OR: "ɔɹ",
  ER: "ɜːɹ",
  IR: "ɜːɹ",
  UR: "ʊɹ",
};

const ipaToGraphemeMap = {
  // Consonants
  p: ["p", "pp"], // "pen", "apple"
  b: ["b", "bb"], // "bat", "bubble"
  t: ["t", "tt", "ed"], // "top", "letter", "looked"
  d: ["d", "dd", "ed"], // "dog", "add", "played"
  k: ["k", "c", "ck", "ch", "cc", "que"], // "cat", "kick", "character", "accord", "boutique"
  g: ["g", "gg", "gh", "gue"], // "go", "egg", "ghost", "league"
  f: ["f", "ff", "ph", "gh"], // "fish", "coffee", "phone", "laugh"
  v: ["v", "f", "ph"], // "van", "of", "Stephen"
  θ: ["th"], // "thin", "with"
  ð: ["th"], // "this", "other"
  s: ["s", "ss", "c", "sc", "ps"], // "see", "class", "cent", "science", "psychology", "dance"
  z: ["z", "zz", "s", "ss", "x"], // "zoo", "buzz", "is", "dessert", "xylophone"
  ʃ: ["sh", "ti", "ci", "ch", "ssi", "ce", "sci", "s"], // "she", "nation", "precious", "machine", "passion", "ocean", "conscious"
  ʒ: ["s", "ge", "g", "j", "dg"], // "measure", "genre", "mirage", "journey", "bridge"
  tʃ: ["ch", "tch", "tu", "ti"], // "chip", "watch", "nature", "question"
  dʒ: ["j", "g", "dge", "di"], // "jam", "giraffe", "edge", "soldier"
  m: ["m", "mm", "mn", "mb"], // "man", "hammer", "column", "thumb"
  n: ["n", "nn", "kn", "gn", "pn"], // "no", "dinner", "knee", "gnat", "pneumonia"
  ŋ: ["ng", "n"], // "ring", "sink"
  h: ["h"], // "hat"
  l: ["l", "ll"], // "lip", "bell"
  r: ["r", "rr", "wr", "rh"], // "run", "arrow", "write", "rhyme"
  j: ["y", "i"], // "yes", "onion", "Himalayas"
  w: ["w", "wh"], // "wet", "whale"

  // Vowels
  iː: ["ee", "ea", "e", "ie", "ei", "ey", "i", "y"], // "see", "sea", "be", "field", "ceiling", "key", "machine", "happy"
  ɪ: ["i", "y", "e", "ui", "ie"], // "sit", "gym", "pretty", "build", "ladies"
  e: ["e", "ea", "ai", "ie", "a"], // "bed", "head", "said", "friend", "any"
  æ: ["a", "ai"], // "cat", "plaid"
  ɑː: ["ar", "a", "au", "er"], // "car", "father", "aunt", "clerk"
  ɔː: ["or", "aw", "au", "ough"], // "born", "saw", "haul", "thought"
  oʊ: ["o", "oa", "ow", "oe", "ough"], // "go", "boat", "snow", "toe", "though"
  ʊ: ["u", "oo", "ou"], // "put", "foot", "could"
  uː: ["oo", "ue", "ew", "o", "ui", "ou", "ough", "eu"], // "food", "blue", "grew", "do", "fruit", "soup", "through"
  ʌ: ["u", "o", "ou"], // "cup", "son", "touch"
  ə: ["a", "e", "i", "o", "u", "y"], // "sofa", "taken", "pencil", "syrup", "banana", "myth"
  ɛ: ["e", "ea"],

  // Diphthongs
  aɪ: ["i", "y", "igh", "ie", "uy", "ai", "eye"], // "time", "my", "light", "pie", "guy", "aisle", "eye"
  eɪ: ["a", "ai", "ay", "ei", "ey", "ea"], // "face", "wait", "day", "vein", "they", "great"
  ɔɪ: ["oy", "oi"], // "boy", "coin"
  aʊ: ["ow", "ou"], // "now", "house"
  oʊ: ["o", "oa", "ow", "oe", "ew"], // "go", "boat", "snow", "toe", "sew"

  // R-colored vowels (R-controlled)
  ɜːɹ: ["er", "ir", "ur", "ear", "or", "err"], // "her", "bird", "turn", "learn", "work", "merry"
  ɑːɹ: ["ar", "ear", "a", "are"], // "car", "heart", "father", "are"
  ɔːɹ: ["or", "ore", "oar", "our", "aur"], // "for", "store", "roar", "four", "aurora"
  ɪɹ: ["eer", "ere", "ear", "ier", "eir", "irr"], // "beer", "here", "fear", "pier", "weird"
  eɪɹ: ["air", "are", "ear", "eir"], // "fair", "care", "bear", "heir"
  ʊɹ: ["ure", "our", "ur", "ooer", "oore"], // "pure", "tour", "curious", "sure", "poor"
  eɹ: ["er", "ar", "or", "ur", "ear", "err", "air", "are"], // "air", "where", "error", "furry", "bear", "merry"
  aʊɜː: ["our", "ower", "awer"], // "hour", "power", "flower"
  oʊɹ: ["or", "ore", "oar", "our"], // "shore", "more", "roar", "four"
  aɪɹ: ["ire", "ier", "yre", "eir"],
};

// Function to convert CMU phonemes to IPA with handling for stress markers
export function convertCmuToIpa(cmuPhonemes) {
  const ipaPhonemes = [];
  let i = 0;

  // Define a map for R-colored transformations
  const rColoredMap = {
    AW: "aʊɜː", // "hour"
    AA: "ɑːɹ", // "car"
    AO: "ɔːɹ", // "door"
    EH: "eɹ", // "air" or "where"
    IH: "ɪɹ", // "mirror"
    ER: "ɜːɹ", // "bird"
    UH: "ʊɹ", // "sure" or "tour"
    IY: "ɪɹ", // "ear" or "near"
    EY: "eɪɹ", // "bear" or "care"
    OW: "oʊɹ", // "more" or "shore"
    AY: "aɪɹ",
  };

  while (i < cmuPhonemes.length) {
    let phoneme = cmuPhonemes[i];
    let basePhoneme = phoneme.replace(/[0-2]/g, ""); // Remove stress markers

    // Check if R follows for potential R-colored transformation
    const nextPhoneme =
      cmuPhonemes[i + 1] && cmuPhonemes[i + 1].replace(/[0-2]/g, "");
    if (
      (nextPhoneme === "ER" || nextPhoneme === "R") &&
      rColoredMap[basePhoneme]
    ) {
      ipaPhonemes.push(rColoredMap[basePhoneme]); // Use R-colored IPA
      i += 2; // Skip the R
    } else {
      // Use standard mapping if no R-colored transformation
      ipaPhonemes.push(cmuToIpaMap[basePhoneme] || basePhoneme);
      i += 1;
    }
  }

  return ipaPhonemes;
}

// Maps IPA phonemes to grapheme tiles
export function mapIpaToGraphemes(ipaPhonemes, word) {
  let graphemeTiles = [];
  let tempWord = word;

  // Detect if the initial letter is not represented in ipaPhonemes
  if (ipaPhonemes.length > 0) {
    const firstPhonemeGraphemes = ipaToGraphemeMap[ipaPhonemes[0]] || [];
    const firstLetter = tempWord[0];

    // Check if the word starts with a letter that doesn’t match the first phoneme grapheme options
    if (
      !firstPhonemeGraphemes.some((grapheme) => tempWord.startsWith(grapheme))
    ) {
      graphemeTiles.push(firstLetter); // Assume the first letter is silent
      tempWord = tempWord.slice(1); // Remove it from the word
    }
  }

  ipaPhonemes.forEach((ipaPhoneme, index) => {
    const possibleGraphemes = ipaToGraphemeMap[ipaPhoneme] || [];
    let matched = false;

    // Sort possible graphemes by length to prioritize longer matches first
    const sortedGraphemes = possibleGraphemes.sort(
      (a, b) => b.length - a.length
    );

    for (const grapheme of sortedGraphemes) {
      // Check for "sc" case where "c" needs to be separate to make a "k" sound
      if (
        ipaPhoneme === "s" &&
        ipaPhonemes[index + 1] === "k" &&
        grapheme === "sc"
      ) {
        continue; // Skip "sc" if it shouldn't be used
      }

      if (tempWord.startsWith(grapheme)) {
        graphemeTiles.push(grapheme);
        tempWord = tempWord.slice(grapheme.length);
        matched = true;
        break;
      }
    }

    // If no grapheme was matched, assume the first letter is silent
    if (!matched && tempWord.length > 0) {
      graphemeTiles.push(tempWord[0]);
      tempWord = tempWord.slice(1);
    }
  });

  // Handle any remaining letters in the word as silent
  while (tempWord.length > 0) {
    graphemeTiles.push(tempWord[0]);
    tempWord = tempWord.slice(1);
  }

  return graphemeTiles;
}

// Fetch phonemes for a word from an API
export async function getPhonemes(word, overloaded = 0) {
  try {
    const response = await axios.get(`/api/phonemes?word=${word}`);
    return response.data[overloaded];
  } catch (error) {
    console.error("Error fetching phonemes:", error);
    return null;
  }
}

// New function to get syllable information using CMU
export async function getSyllableInfoFromCmu(word) {
  try {
    const response = await axios.get(`/api/syllables?word=${word}`);
    const syllables = response.data.syllables || [];

    return syllables.map((syllable) => {
      // Determine syllable type based on phoneme patterns
      if (syllable.includes("ː") || syllable.endsWith("e")) return "v_e";
      if (syllable.includes("ə")) return "schwa";
      if (/[aæɛiɔʊ]/.test(syllable)) return "breve"; // Short vowels
      if (/[iːuːeɪoʊ]/.test(syllable)) return "macron"; // Long vowels
      return "";
    });
  } catch (error) {
    console.error("Error fetching syllable information:", error);
    return [];
  }
}
