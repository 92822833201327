import React from "react";
import "./ProgressBar.scss";

const ProgressBar = ({
  totalSlidesInAllSessions,
  completedSlides,
  fullscreen,
}) => {
  const progress = (
    ((completedSlides + 1) / totalSlidesInAllSessions) *
    100
  ).toFixed(2);

  return (
    <div className={`progress-bar ${fullscreen && "progress-bar-fullscreen"}`}>
      <div className="progress-bar-inner" style={{ width: `${progress}%` }} />
    </div>
  );
};

export default ProgressBar;
